'use client';

import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { getNewMessagesCount } from 'common-toolbox/src/clients/messages';

import CSS from './LinkDecorator.module.scss';
import { NavbarLinkType } from './hooks/exposedNavbarLinks';

type LinkDecoratorProps = {
  link: NavbarLinkType;
  children: ReactNode;
};

function NewMessagesDecorator({ children }: { children: ReactNode }) {
  const { data: unreadMessages } = useQuery({
    queryKey: ['new-messages'],
    queryFn: getNewMessagesCount,
    refetchOnMount: false,
    refetchOnWindowFocus: true,
  });

  return (
    <div
      className={clsx(CSS.LinkDecorator, {
        [CSS.NewMessages]: unreadMessages > 0,
      })}
    >
      {children}
    </div>
  );
}

function LinkDecorator({ link, children }: LinkDecoratorProps) {
  if (
    link.url === `${process.env.NEXT_PUBLIC_USER_ENVIRONMENT_HOST}/messages/`
  ) {
    return <NewMessagesDecorator>{children}</NewMessagesDecorator>;
  }
  return <>{children}</>;
}

export default LinkDecorator;
